import * as $ from 'jquery';

declare function __tcfapi(command: string, version: number, callback: any, parameter?: any): void;

// Set consent defaults.
window.thirdPartyConsentAccepted = {
    StoreAndAccessInformation: false,
    SelectBasicAds: false,
    CreateAdsProfile: false,
    SelectPersonalisedAds: false,
    CreatePersonalisedProfile: false,
    SelectPersonalisedContent: false,
    MeasureAdPerformance: false,
    MeasureContentPerformance: false,
    GenerateAudienceInsights: false,
    DevelopProducts: false,
    UseGeolocationData: false,
    ScanDevice: false
};

window.trackingScriptsEnabled = {
    allConsentsAndTrackings: false,
    Facebook: false,
    Google: false,
    Gemius: false,
    LinkedIn: false,
    Agillic: false,
    Apsis: false,
    Maze: false
}

export class CookieConsent {
    static init() {

        if (window.useQc) {
            // setup event listener for quantcast v2 events
            // https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
            __tcfapi('addEventListener', 2, (tcData, success) => {
                if (success && tcData.eventStatus === 'tcloaded') {
                    // this function is called when the page is loaded
                    if (tcData.tcString !== null && tcData.tcString !== undefined && tcData.tcString.toString().length > 0) {
                        // we got the consent string already => proceed
                        this.getUserConsentChoices();
                    }
                }

                if (success && tcData.eventStatus === 'useractioncomplete') {
                    // this function is called when:
                    // - the user accepts / rejects / updates the consent terms
                    // - the user dismisses the consent box by clicking the 'x'
                    this.getUserConsentChoices();
                }
            });
        } else {
            // If quantcast is disabled.
            this.callFunction(window.enableFacebookPixel);
            this.callFunction(window.googleTagManagerGrantConsent);
            this.callFunction(window.enableGemiusTracking);
            this.pendingScript();
        }
    }

    /**
     * Get the user consent choices
     **/
    private static getUserConsentChoices(): void {
        let thirdPartyPurposeConsentList = undefined;
        let thirdPartyPurposeConsentListSpecialFeatures = undefined;
        const indexedPurposesSpecialFeaturesList = {
            "1": {
                "id": 1,
                "name": "Use precise geolocation data"
            },
            "2": {
                "id": 2,
                "name": "Actively scan device characteristics for identification"
            }
        };
        const indexedPurposesList = {
            "1": {
                "id": 1,
                "name": "Store and/or access information on a device"
            },
            "2": {
                "id": 2,
                "name": "Select basic ads"
            },
            "3": {
                "id": 3,
                "name": "Create a personalised ads profile"
            },
            "4": {
                "id": 4,
                "name": "Select personalised ads"
            },
            "5": {
                "id": 5,
                "name": "Create a personalised content profile"
            },
            "6": {
                "id": 6,
                "name": "Select personalised content"
            },
            "7": {
                "id": 7,
                "name": "Measure ad performance"
            },
            "8": {
                "id": 8,
                "name": "Measure content performance"
            },
            "9": {
                "id": 9,
                "name": "Apply market research to generate audience insights"
            },
            "10": {
                "id": 10,
                "name": "Develop and improve products"
            }
        };

        // get transparency and consent data - check 3rd party cookie consent
        __tcfapi('getTCData', 2, (tcData, success) => {
            if (success) {
                thirdPartyPurposeConsentList = tcData.purpose.consents;
                thirdPartyPurposeConsentListSpecialFeatures = tcData.specialFeatureOptins;
                this.enforceConsent(indexedPurposesList, indexedPurposesSpecialFeaturesList, thirdPartyPurposeConsentList, thirdPartyPurposeConsentListSpecialFeatures);
            }
        });
    }

    /**
     * filters object based on name and returns the id
     * @param object
     * @param value
     */
    private static filterObject(object, value): any {
        return Object.keys(object).reduce((r, e) => {
            if (object[e].name === value) {
                r = object[e].id;
            }
            return r;
        }, {})
    }

    /**
     * check if function exists before calling it
     * @param fn
     */
    private static callFunction(fn): void {
        if ($.isFunction(fn)) {
            fn();
        }
    }

    private static pendingScript(): void {
        /* ************************************************************************
         * process queued up script executions such as widgets and other tracking *
         * ************************************************************************/
        // first capture push function, so new calls are executed immediately
        window.pendingScriptExecutions.push = function (callbackObj) {
            if (callbackObj.callback instanceof Function) {
                callbackObj.callback.call(callbackObj.context);
            }
            return 0;
        }
        // then empty the existing queue of callbacks already added
        window.pendingScriptExecutions.forEach(function (v, i, a) {
            window.pendingScriptExecutions.push(v);
        });
    }

    /**
     * Enforce consent.
     * @param indexedPurposesList
     * @param indexedPurposesSpecialFeaturesList
     * @param thirdPartyPurposeConsentList
     * @param thirdPartyPurposeConsentListSpecialFeatures
     */
    private static enforceConsent(indexedPurposesList, indexedPurposesSpecialFeaturesList, thirdPartyPurposeConsentList, thirdPartyPurposeConsentListSpecialFeatures): void {
        /* **********************************************
         * Extract user consent settings from QuantCast *
         * **********************************************/

        // set consent choices
        const thirdPartyConsentChoices = {
            StoreAndAccessInformation: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Store and/or access information on a device")],
            SelectBasicAds: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Select basic ads")],
            CreateAdsProfile: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Create a personalised ads profile")],
            SelectPersonalisedAds: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Select personalised ads")],
            CreatePersonalisedProfile: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Create a personalised content profile")],
            SelectPersonalisedContent: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Select personalised content")],
            MeasureAdPerformance: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Measure ad performance")],
            MeasureContentPerformance: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Measure content performance")],
            GenerateAudienceInsights: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Apply market research to generate audience insights")],
            DevelopProducts: thirdPartyPurposeConsentList[this.filterObject(indexedPurposesList, "Develop and improve products")],
            UseGeolocationData: thirdPartyPurposeConsentListSpecialFeatures[this.filterObject(indexedPurposesSpecialFeaturesList, "Use precise geolocation data")],
            ScanDevice: thirdPartyPurposeConsentListSpecialFeatures[this.filterObject(indexedPurposesSpecialFeaturesList, "Actively scan device characteristics for identification")]
        };

        window.thirdPartyConsentAccepted.StoreAndAccessInformation = thirdPartyConsentChoices.StoreAndAccessInformation !== undefined ? thirdPartyConsentChoices.StoreAndAccessInformation : false;
        window.thirdPartyConsentAccepted.SelectBasicAds = thirdPartyConsentChoices.SelectBasicAds !== undefined ? thirdPartyConsentChoices.SelectBasicAds : false;
        window.thirdPartyConsentAccepted.CreateAdsProfile = thirdPartyConsentChoices.CreateAdsProfile !== undefined ? thirdPartyConsentChoices.CreateAdsProfile : false;
        window.thirdPartyConsentAccepted.SelectPersonalisedAds = thirdPartyConsentChoices.SelectPersonalisedAds !== undefined ? thirdPartyConsentChoices.SelectPersonalisedAds : false;
        window.thirdPartyConsentAccepted.CreatePersonalisedProfile = thirdPartyConsentChoices.CreatePersonalisedProfile !== undefined ? thirdPartyConsentChoices.CreatePersonalisedProfile : false;
        window.thirdPartyConsentAccepted.SelectPersonalisedContent = thirdPartyConsentChoices.SelectPersonalisedContent !== undefined ? thirdPartyConsentChoices.SelectPersonalisedContent : false;
        window.thirdPartyConsentAccepted.MeasureAdPerformance = thirdPartyConsentChoices.MeasureAdPerformance !== undefined ? thirdPartyConsentChoices.MeasureAdPerformance : false;
        window.thirdPartyConsentAccepted.MeasureContentPerformance = thirdPartyConsentChoices.MeasureContentPerformance !== undefined ? thirdPartyConsentChoices.MeasureContentPerformance : false;
        window.thirdPartyConsentAccepted.GenerateAudienceInsights = thirdPartyConsentChoices.GenerateAudienceInsights !== undefined ? thirdPartyConsentChoices.GenerateAudienceInsights : false;
        window.thirdPartyConsentAccepted.DevelopProducts = thirdPartyConsentChoices.DevelopProducts !== undefined ? thirdPartyConsentChoices.DevelopProducts : false;
        window.thirdPartyConsentAccepted.UseGeolocationData = thirdPartyConsentChoices.UseGeolocationData !== undefined ? thirdPartyConsentChoices.UseGeolocationData : false;
        window.thirdPartyConsentAccepted.ScanDevice = thirdPartyConsentChoices.ScanDevice !== undefined ? thirdPartyConsentChoices.ScanDevice : false;

        // enable all tracking scripts
        window.trackingScriptsEnabled.allConsentsAndTrackings =
            window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
            window.thirdPartyConsentAccepted.SelectBasicAds &&
            window.thirdPartyConsentAccepted.CreateAdsProfile &&
            window.thirdPartyConsentAccepted.SelectPersonalisedAds &&
            window.thirdPartyConsentAccepted.CreatePersonalisedProfile &&
            window.thirdPartyConsentAccepted.SelectPersonalisedContent &&
            window.thirdPartyConsentAccepted.MeasureAdPerformance &&
            window.thirdPartyConsentAccepted.MeasureContentPerformance &&
            window.thirdPartyConsentAccepted.GenerateAudienceInsights &&
            window.thirdPartyConsentAccepted.DevelopProducts &&
            window.thirdPartyConsentAccepted.UseGeolocationData &&
            window.thirdPartyConsentAccepted.ScanDevice;

        // enable Gemius tracking
        window.trackingScriptsEnabled.Gemius =
            window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
            window.thirdPartyConsentAccepted.MeasureContentPerformance &&
            window.thirdPartyConsentAccepted.DevelopProducts &&
            window.thirdPartyConsentAccepted.ScanDevice;

        // enable Facebook, LinkedIn and Google and Apsis tracking
        window.trackingScriptsEnabled.Facebook = window.trackingScriptsEnabled.LinkedIn = window.trackingScriptsEnabled.Google =
            window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
            window.thirdPartyConsentAccepted.MeasureContentPerformance &&
            window.thirdPartyConsentAccepted.DevelopProducts &&
            window.thirdPartyConsentAccepted.ScanDevice &&
            window.thirdPartyConsentAccepted.CreateAdsProfile &&
            window.thirdPartyConsentAccepted.SelectPersonalisedAds &&
            window.thirdPartyConsentAccepted.MeasureAdPerformance &&
            window.thirdPartyConsentAccepted.GenerateAudienceInsights &&
            window.thirdPartyConsentAccepted.SelectPersonalisedContent &&
            window.thirdPartyConsentAccepted.SelectBasicAds &&
            window.thirdPartyConsentAccepted.CreatePersonalisedProfile;

        // enable Apsis tracking
        window.trackingScriptsEnabled.Apsis =
            window.trackingScriptsEnabled.Gemius &&
            window.thirdPartyConsentAccepted.CreateAdsProfile &&
            window.thirdPartyConsentAccepted.SelectPersonalisedAds &&
            window.thirdPartyConsentAccepted.MeasureAdPerformance;

        // enable Agillic tracking
        window.trackingScriptsEnabled.Agillic =
            window.thirdPartyConsentAccepted.CreateAdsProfile &&
            window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
            window.thirdPartyConsentAccepted.MeasureAdPerformance;

        /* enable Maze tracking */
        window.trackingScriptsEnabled.Maze =
            window.thirdPartyConsentAccepted.CreatePersonalisedProfile;

        /* ***************************************************************
         * Determine which scripts and partials should be enabled/loaded *
         * ***************************************************************/

        // gemius
        if (window.trackingScriptsEnabled.Gemius) {
            this.callFunction(window.enableGemiusTracking); // _Gemius.cshtml
        } else {
            this.callFunction(window.disableGemiusTracking); // _Gemius.cshtml
        }

        // facebook script
        if (window.trackingScriptsEnabled.Facebook) {
            window.dataLayer.push({ event: 'FacebookConsent' });
        }

        // google analytics
        if (window.trackingScriptsEnabled.Google) {
            this.callFunction(window.googleTagManagerGrantConsent); //_GoogleTagManagerHead.cshtml
            window.dataLayer.push({ event: 'GoogleConsent' });
        } else {
            this.callFunction(window.googleTagManagerRevokeConsent); //_GoogleTagManagerHead.cshtml
        }

        // linkedIn script
        if (window.trackingScriptsEnabled.LinkedIn) {
            window.dataLayer.push({ event: 'LinkedinConsent' });
            this.callFunction(window.linkedInGrantConsent); // _LinkedInInsightTag.cshtml
        }
        else {
            this.callFunction(window.linkedInRevokeConsent); // _LinkedInInsightTag.cshtml
        }

        // apsis
        if (window.trackingScriptsEnabled.Apsis) {
            this.callFunction(window.enableApsis); //_Apsis.cshtml
        }
        else {
            this.callFunction(window.disableApsis); //_Apsis.cshtml
        }

        // agillic
        if (window.trackingScriptsEnabled.Agillic) {
            window.dataLayer.push({ event: "AgillicConsent" });
        }

        // maze
        if (window.trackingScriptsEnabled.Maze) {
            window.dataLayer.push({ event: 'MazeConsent' });
        }

        // sleeknote
        if (window.trackingScriptsEnabled.allConsentsAndTrackings) {
            this.callFunction(window.enableSleeknote());
        }

         // playable
        if (window.trackingScriptsEnabled.allConsentsAndTrackings) {
             this.callFunction(window.enablePlayable);
         } else {
             this.callFunction(window.disablePlayable);
         }

        this.pendingScript();
    }
}
