import * as $ from 'jquery';
import 'bootstrap';
import { Menu } from "./menu"
import { Carousel } from "./carousel";
import { CloseFilter } from "./closeFilter";
import { Tip } from "./tip";
import { Search } from "./search";
import { Section } from "./section";
import { CookieConsent } from "./cookie.consent";
import { RegionSelector } from "./region.selector";
import { SimpleOnBoarding } from "./simple.on.boarding";
import { Recommendation } from "./recommendation";
import * as Bricklayer from 'bricklayer';
import './facebook';
import GoogleAdManager from './Ads/google-ad-manager';
import { SeriesportNu } from './Seriesportnu/seriesportnu.init';
import { LigeHerNu } from "./Ligehernu/ligehernu.init";
import { HeaderScroll } from './headerScroll';
import FeaturedEstate from './featuredEstate';
import { articleSwiper } from './articleSwiper';

/* Global inits */
$(function () {
  console.debug('Initializing app!');

  CookieConsent.init();
  Menu.init();
  Carousel.init();
  FeaturedEstate.initSwiper();
  Search.init();
  Tip.init();
  Section.init();
  RegionSelector.init();
  SimpleOnBoarding.init();
  GoogleAdManager.init();
  Recommendation.init();
  CloseFilter.init();
  HeaderScroll.init();
  LoadIfRegionMatches([siteRegions.SeriesportNu], SeriesportNu, SeriesportNu.init);
  articleSwiper.init();

  LoadIfRegionMatches([siteRegions.LigeHerNu], LigeHerNu, LigeHerNu.init);
  document.querySelectorAll('.bricklayer').forEach(function (section) {
    new Bricklayer(section);
  });
});

// All sites maintained by app.ts
// the value is specified in views/partial/section/index.cshtml in the meta tags
const siteRegions = {
  SeriesportNu: "Seriesport.Nu",
  LigeHerNu: "Ligeher.nu"
}

// Function that gets the current loaded webpage
// and checks if the region supplied matches it.
// then executes the function given in callback and returns true
function LoadIfRegionMatches(region: string[], callbackType, callback: () => void): boolean {
  // The tag which contains which site is loaded
  var currentSiteRegion = $("meta[name=apple-mobile-web-app-title]").attr("content");
  var result = false;

  // Dont run the function if current site isnt in siteRegions
  if (!Object.values(siteRegions).some(siteName => siteName === currentSiteRegion)) {
    return false;
  }

  // Loops through all sites in siteRegions
  // and checks if any of the sites supplied in region matches currentSiteRegion
  if (region.some(selectedSite => selectedSite === currentSiteRegion)) {
    result = true;
  }

  // Calls the function supplied in callback
  // and sets "this" to callbackType context within the callback.
  if (result) {
    callback.call(callbackType);
  }
  return result;
}
